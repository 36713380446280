import React, {Component} from "react";
import Card from "./Card";

class ReelCard extends Component {

  constructor(props, context) {
    super(props);

    this.state = {
      isModalActive: false
    }
  }


  toggleActive = () => {
    this.setState({
      isModalActive: !this.state.isModalActive
    })
  }

  render() {
    return (
        <>
          <div
              className={`reel-card ${this.props.isModalModeActive ? "reel-card--modal" : ""} ${this.state.isModalActive ? "reel-card--modal-active" : ""}`}
              // onClick={() => this.toggleActive()}
          >
            <div className={"reel-card__asset"}>
              <img src={this.props.assetPath} alt="card-asset"/>
            </div>

            {!this.props.isAssetOnly &&
            <Card/>
            }
          </div>
        </>
    );
  }
}

export default ReelCard;
