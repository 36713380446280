import React, {Component} from "react";

export const AppContext = React.createContext();

class ContextProvider extends Component {

  state = {
    isSpaceOpen: false,

    setSpaceOpen: isOpen => {
      console.log("setSpace called")
      this.setState({
        isSpaceOpen: isOpen
      })
    },

    toggleSpaceOpen: () => {
      console.log("toggle called")
      this.setState({
        isSpaceOpen: !this.state.isSpaceOpen
      })
    }

  };

  componentDidMount() {
  }

  render() {
    return (
        <AppContext.Provider value={this.state}>
          {this.props.children}
        </AppContext.Provider>
    );
  }
}

export default ContextProvider;
