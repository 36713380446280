import React from 'react';
import ReactDOM from 'react-dom';
import './index.sass';
import App from './App';
import ContextProvider from "./components/ContextProvider";

ReactDOM.render(
    <ContextProvider>
      <App/>
    </ContextProvider>,
    document.getElementById('root')
);

