import React, {Component} from "react";

class Button extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {
    return (
        <div className={"scroll-up__wrapper"} onClick={() => window.scrollTo(0, 0)}>
          <div className="scroll-up">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6">
              <defs></defs>
              <g
                  id="Ebene_2" data-name="Ebene 2">
                <g id="Ebene_1-2" data-name="Ebene 1">
                  <rect id="Rechteck_26"
                        data-name="Rechteck 26"
                        className="cls-1"
                        x="-2.69" y="9.31"
                        width="28" height="4"
                        transform="translate(-4.69 11.31) rotate(-45)"/>
                  <rect
                      id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"
                      height="28" transform="translate(-16.69 16.28) rotate(-45)"/>
                </g>
              </g>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6">
              <defs></defs>
              <g
                  id="Ebene_2" data-name="Ebene 2">
                <g id="Ebene_1-2" data-name="Ebene 1">
                  <rect id="Rechteck_26"
                        data-name="Rechteck 26"
                        className="cls-1"
                        x="-2.69" y="9.31"
                        width="28" height="4"
                        transform="translate(-4.69 11.31) rotate(-45)"/>
                  <rect
                      id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"
                      height="28" transform="translate(-16.69 16.28) rotate(-45)"/>
                </g>
              </g>
            </svg>

            <span>BACK<span> TO TOP</span></span>

          </div>
        </div>
    );
  }
}

export default Button;
