import React, {Component} from "react";
import {withRouter} from "react-router";
import LottieControlled from "../components/atoms/LottieControlled";
import Breadcrumb from "../components/atoms/Breadcrumb";
import designBlack from "../lottie/anim--form-to-function-black.json"
import designWhite from "../lottie/anim--form-to-function-white.json"

class DesignPage extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {

    return (
        <div className="life__upper design">
          <div className="content">
            <Breadcrumb previous="über" current={"design"} isBackVisible={true}/>
            <div className="content__inner--life">
              <div className="detail__container">
                <div className="detail__header">
                  <div className="uber__stage">
                    <LottieControlled
                        animationData={this.props.isDarkMode ? designWhite : designBlack}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(DesignPage);
