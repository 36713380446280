import React, {Component} from "react";
import {withRouter} from "react-router";
import Breadcrumb from "../components/atoms/Breadcrumb";
import ScrollUp from "../components/atoms/ScrollUp";

class ImprintPage extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {

    return (
        <div className="life imprint">
          <div className="content">
            <Breadcrumb current={"impressum"} isBackVisible={true}/>

            <div className="imprint__container">
              <h1>IMPRESSUM</h1>
              <p>Kommunikationsdesigner B.A. (HS) Julian Faller <br/>
                Hindenburgstr. 20<br/>
                D-55118 Mainz<br/>
                Tel: +49 17662732872<br/>
                E-Mail: j.faller@fallerjansen.de<br/>
                <br/>
                Verantwortlich für den Inhalt i.S.d. § 6 M DStV und 6 TDG:<br/>
                Kommunikationsdesigner B.A. (HS) Julian Faller<br/>
                Haftungsausschluss (Disclaimer) <br/>
              </p>
              <h3>Haftung für Inhalte</h3>
              <p>Die Inhalte meiner Seiten wurden mit größter Sorgfalt erstellt.
                Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann ich jedoch keine Gewähr übernehmen.
                Als Diensteanbieter bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
                der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werde ich diese Inhalte umgehend
                entfernen.</p>
              <h3>Haftung für Links</h3>
              <p>Mein Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte
                ich keinen Einfluss haben. Deshalb kann ich für diese fremden Inhalte auch keine Gewähr übernehmen. Für
                die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
                verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
                überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
                inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.</p>
              <h3>Urheberrecht</h3>
              <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
                Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede
                Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
                jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht
                kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
                wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um
                einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werde ich derartige Inhalte
                umgehend entfernen.</p>
            </div>
          </div>
          <ScrollUp/>
        </div>
    );
  }
}

export default withRouter(ImprintPage);
