import React, {Component} from "react";
import {Link} from "react-router-dom";
import {AppContext} from "../ContextProvider";

class Navbar extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {

    return (
        <div className="navbar">
          <div className="navbar__links">
            <ul>
              <li>
                <Link to={{pathname: `/`}} onClick={() => this.context.setSpaceOpen(false)}>
                  <img className={"navbar__logo"}
                       src={`/assets/03_logo/vulcano_logo_${this.props.isDarkMode ? "black" : "white"}_nofont.png`}
                       alt="logo"/>
                </Link>
              </li>
            </ul>
          </div>

          <div className={`navbar__actions ${this.context.isSpaceOpen ? "navbar__actions--active" : ""}`}>
            <ul>
              <li>
                <Link to={{pathname: `/`}} onClick={() => this.context.toggleSpaceOpen()}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 24">
                    <defs>
                    </defs>
                    <g id="Ebene_2" data-name="Ebene 2">
                      <g id="Ebene_1-2" data-name="Ebene 1">
                        <g id="Komponente_17_45" data-name="Komponente 17 45">
                          <rect id="Rechteck_25" data-name="Rechteck 25" className="cls-1" width="40" height="4"/>
                          <rect id="Rechteck_26" data-name="Rechteck 26" className="cls-1" y="10" width="40"
                                height="4"/>
                          <rect id="Rechteck_27" data-name="Rechteck 27" className="cls-1" y="20" width="40"
                                height="4"/>
                        </g>
                      </g>
                    </g>
                  </svg>
                </Link>
              </li>
            </ul>
          </div>
        </div>
    );
  }
}

Navbar.contextType = AppContext

export default Navbar;
