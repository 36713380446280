import React, {Component} from "react";
import {withRouter} from "react-router";
import Breadcrumb from "../components/atoms/Breadcrumb";
import ScrollUp from "../components/atoms/ScrollUp";

class InspirationPage extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {

    return (
        <div className="life imprint inspiration">
          <div className="content">
            <Breadcrumb previous={"über"} current={"jan verwoert"} isBackVisible={true}/>
            <div className="imprint__container">
              <h1>EXHAUSTION & EXUBERANCE</h1>
              <b>Ways to Defy the Pressure to Perform by Jan Verwoert</b>
              <h4>The Pressure to Perform</h4>
              <p>How can we address the current changes in our societies and lives? Some say that we have come to
                inhabit the post-industrial condition —but what does that mean? One thing seems certain: after the
                disappearance of Manual labour from the lives of most people in the Western world, we have entered into
                a culture where we no longer just work, we perform. We need to perform because that is what’s asked of
                us. When we choose to make our living on the basis of doing what we want, we are required to get our act
                together and get Things done, in any place, at any time. Are you ready? I ask you and I’m sure that
                you’re as Ready as you’ll ever be to perform, prove yourself, do things and go places.</p>
              <p>Who is we? The group is ever-expanding. It is we, the creative types—who invent jobs for ourselves by
                exploring and exploiting our talents to perform small artistic and intellectual miracles on a daily
                basis. It is we, the socially engaged—who create communal spaces for others and ourselves by performing
                as instigators or facilitators of social exchange. When we perform, we generate communication and
                thereby build forms of communality.</p>
              <p>1.<br/>When we perform, we develop ideas and thereby provide the content for an economy based on the
                circulation of a new currency: information. In doing so, we produce the social and intellectual capital
                that service societies thrive on today, in the so-called Information Age. Accordingly, the Deutsche Bank
                sums up their corporate philosophy with the Slogan A Passion to Perform. (The motto is symptomatically
                agrammatical: in English, someone can be said to have a passion for something or someone, but not a
                passion to.)</p>
              <p>2.<br/> Management consultants confirm that “implementing, promoting and sustaining a high Performance
                culture” is the key to increasing corporate productivity by eliciting individual commitment and
                competitiveness among employees.</p>
              <p>3.<br/> So which side of the barricades are we on? Where do they stand today anyway? When do we commit
                to perform of our own free will? And when is our commitment elicited under false pretenses to enforce
                the ideology of high performance and boost someone else’s profits? How can we tell the difference? And
                who is there to blame, if we choose to exploit ourselves? In a high performance culture, we are the
                avant-garde but we are also the job-slaves. We serve the customers who consume the communication and
                sociability that we produce. We work in the call centers and in the kitchens of recently opened
                restaurants in the burgeoning new urban hubs of the global service society.<br/><br/>
                To provide our services we are willing to travel, so we go west to perform, we go North to perform. We
                are everywhere, fixing the
                minds, houses and cars of local customers wherever we end up staying because there is work available —
                and for as long as it’s available. Living this life of high performance we are constantly facing two
                questions: “Are we (still) in charge?” and: “Are we (still) happy?” They are the Questions of agency and
                the good life, and both are implied in the first question of political ethics: “How can we know what
                would be the right thing to do to make a better life possible for ourselves and others, now and in the
                future?” Every time this question arises, it leaves us craving for an overall solution, a resolute
                stance, a set of unassailable principles or a foundational politics that would provide us with
                unambiguous criteria for determining the right thing to do in each and every future situation. The
                trouble is that just as the Question only arises in situations when a specific decision is needed, it is
                precisely the specificity of each new situation that seems to pre-empt the very possibility of ever
                devising a general morale or politics that would apply to all instances.
                <br/><br/>
                The requests, invitations or
                opportunities to perform tend to be connected to a set of highly particular conditions that make some
                instances acceptable and others impossible. Under certain circumstances a yes and a no may even seem
                equally justified. Moreover, the conditions under which you will perform may turn out to be completely
                contingent on the way in which you (re)negotiate those conditions in the process of your performance. So
                beyond the yes or no, beyond the either/or, there seem to be a Million other options. Maybe the secret
                of autonomous agency and the good life lies precisely in Opening up the space of those other options
                through a categorical refusal to accept the forceful imposition of any terms, leaving us no choice but
                to choose between either yes or no? […]</p>

              <div className="detail__quote">
                <i>“</i>
                <p>
                  Wir, als sozial engagierten Menschen, schaffen Räume, sind Anstifter und oder Vermittler von sozialem
                  Austausch. Wenn wir performen, erzeugen wir Kommunikation, bauen Formen der Gemeinschaft auf und
                  entwickeln Ideen, die unserer Gesellschaft dienen.
                </p>
                <i>„</i>
              </div>

            </div>
            <ScrollUp/>
          </div>
        </div>
    );
  }
}

export default withRouter(InspirationPage);
