import React, {Component} from "react";
import {withRouter} from "react-router";

class QuotePage extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {
    return (
        <div className="design quote">
          <div className="content">
            <div className="content__inner--life">
              <div className="detail__container">
                <div className="uber__stage">
                  <span className={"quote__close"} onClick={() => this.props.history.push("/")}>
                    x
                  </span>
                  <h1>Lieber<br/>
                    Leere im Raum<br/>
                    als<br/>
                    Leere im Kopf</h1>
                  <span className={"quote__line"}></span>

                  <h4>Dieter Rams</h4>
                  <p>2016</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(QuotePage);
