import React, {Component} from "react";
import {withRouter} from "react-router";
import LottieControlled from "../components/atoms/LottieControlled";
import Breadcrumb from "../components/atoms/Breadcrumb";
import {Link} from "react-router-dom";

class AboutPage extends Component {

  constructor(props, context) {
    super(props);
    this.state = {}
  }

  render() {

    return (
        <div className="life life__upper uber">
          <div className="content">
            <Breadcrumb current={"über"} isBackVisible={true}/>
            <div className="content__inner--life">
              <div className="detail__container">
                <div className="detail__header">
                  <div className={`uber__row`}>
                    <div className="uber__col">
                      <h1>Design</h1>
                      <Link to={"/about/design"}>
                      <span>
                        {this.props.isDarkMode ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                              <defs></defs>
                              <g
                                  id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                  <g id="Gruppe_442"
                                     data-name="Gruppe 442">
                                    <g
                                        id="Gruppe_440" data-name="Gruppe 440">
                                      <circle id="Ellipse_3" data-name="Ellipse 3"
                                              className="cls-1" cx="32" cy="32"
                                          // style={{fill: "#232323"}}
                                              r="32"/>
                                    </g>
                                    <path id="Polygon_2"
                                          data-name="Polygon 2"
                                          className="cls-2"
                                          style={{fill: "#fff"}}
                                          d="M45,32,25,44V20Z"/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                              <defs>

                              </defs>
                              <g id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                  <g id="Gruppe_442" data-name="Gruppe 442">
                                    <g id="Gruppe_440" data-name="Gruppe 440">
                                      <circle id="Ellipse_3" data-name="Ellipse 3"
                                              className="cls-1" cx="32" cy="32"
                                              r="32"/>
                                    </g>
                                    <path id="Polygon_2" data-name="Polygon 2" className="cls-2" d="M45,32,25,44V20Z"
                                          style={{fill: "#232323"}}/>
                                  </g>
                                </g>
                              </g>
                            </svg>
                        }

                      </span>
                      </Link>
                    </div>
                    <div className="uber__col">
                      <h1>Powertools</h1>
                      <Link to={"/about/powertools"}>
                      <span>

                        {this.props.isDarkMode ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                              <defs></defs>
                              <g
                                  id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                  <g id="Gruppe_511"
                                     data-name="Gruppe 511">
                                    <g
                                        id="Gruppe_440" data-name="Gruppe 440">
                                      <circle id="Ellipse_3" data-name="Ellipse 3"
                                              className="cls-1" cx="32" cy="32"
                                              r="32"/>
                                    </g>
                                    <g id="UX" className="cls-2">
                                      <g
                                          className="cls-2" style={{fill: "#fff"}}>
                                        <path className="cls-3" style={{fill: "#fff"}}
                                              d="M28.62,35.93a5.67,5.67,0,0,1-1.35,2.16,5.52,5.52,0,0,1-2.13,1.3,8.53,8.53,0,0,1-2.8.43,6.75,6.75,0,0,1-4.88-1.71A6.82,6.82,0,0,1,15.68,33v-8.8h3.38v8.71a4.12,4.12,0,0,0,.88,2.85,3.16,3.16,0,0,0,2.45,1,3.2,3.2,0,0,0,2.44-.93A3.9,3.9,0,0,0,25.71,33V24.18H29.1v8.69A8.86,8.86,0,0,1,28.62,35.93Z"/>
                                        <path
                                            className="cls-3"
                                            d="M44.36,39.58,40.91,34.3l-3.48,5.28H33.58L39,31.75,33.8,24.18h4l3.24,5,3.25-5H48.1L43,31.7l5.36,7.88Z"/>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                              <defs>
                              </defs>
                              <g id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                  <g id="Gruppe_511" data-name="Gruppe 511">
                                    <g id="Gruppe_440" data-name="Gruppe 440">
                                      <circle id="Ellipse_3" data-name="Ellipse 3"
                                              className="cls-1" cx="32" cy="32"
                                              r="32"/>
                                    </g>
                                    <g id="UX" className="cls-2">
                                      <g className="cls-2" style={{fill: "#232323"}}>
                                        <path className="cls-3"
                                              d="M28.62,35.93a5.67,5.67,0,0,1-1.35,2.16,5.52,5.52,0,0,1-2.13,1.3,8.53,8.53,0,0,1-2.8.43,6.75,6.75,0,0,1-4.88-1.71A6.82,6.82,0,0,1,15.68,33v-8.8h3.38v8.71a4.12,4.12,0,0,0,.88,2.85,3.16,3.16,0,0,0,2.45,1,3.2,3.2,0,0,0,2.44-.93A3.9,3.9,0,0,0,25.71,33V24.18H29.1v8.69A8.86,8.86,0,0,1,28.62,35.93Z"/>
                                        <path className="cls-3"
                                              d="M44.36,39.58,40.91,34.3l-3.48,5.28H33.58L39,31.75,33.8,24.18h4l3.24,5,3.25-5H48.1L43,31.7l5.36,7.88Z"/>
                                      </g>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                        }
                      </span>
                      </Link>
                    </div>
                    <div className="uber__col">
                      <h1>Inspiration</h1>
                      <Link to={"/about/inspiration"}>
                      <span>
                        {this.props.isDarkMode ?
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                              <defs></defs>
                              <g
                                  id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                  <g id="Gruppe_510"
                                     data-name="Gruppe 510">
                                    <g
                                        id="Gruppe_440" data-name="Gruppe 440">
                                      <circle id="Ellipse_3" data-name="Ellipse 3"
                                              className="cls-1" cx="32" cy="32"
                                              r="32"/>
                                    </g>
                                    <g id="Gruppe_515"
                                       data-name="Gruppe 515">
                                      <line
                                          id="Linie_56" data-name="Linie 56" className="cls-2" x1="21" y1="42.38"
                                          x2="45" y2="42.38"
                                          style={{fill: "none", stroke: "#fff", strokeWidth: "2px"}}/>
                                      <line
                                          id="Linie_55" data-name="Linie 55" className="cls-2" x1="16" y1="35.46"
                                          x2="40" y2="35.46"
                                          style={{fill: "none", stroke: "#fff", strokeWidth: "2px"}}/>
                                      <line
                                          id="Linie_54" data-name="Linie 54" className="cls-2" x1="24" y1="28.54"
                                          x2="48" y2="28.54"
                                          style={{fill: "none", stroke: "#fff", strokeWidth: "2px"}}/>
                                      <line
                                          id="Linie_53" data-name="Linie 53" className="cls-2" x1="21" y1="21.62"
                                          x2="45" y2="21.62"
                                          style={{fill: "none", stroke: "#fff", strokeWidth: "2px"}}/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                            :
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64">
                              <defs>
                              </defs>
                              <g id="Ebene_2" data-name="Ebene 2">
                                <g id="Ebene_1-2" data-name="Ebene 1">
                                  <g id="Gruppe_510" data-name="Gruppe 510">
                                    <g id="Gruppe_440" data-name="Gruppe 440">
                                      <circle id="Ellipse_3" data-name="Ellipse 3" className="cls-1" cx="32" cy="32"
                                              r="32"/>
                                    </g>
                                    <g id="Gruppe_515" data-name="Gruppe 515">
                                      <line id="Linie_56" data-name="Linie 56" className="cls-2" x1="21" y1="42.38"
                                            x2="45" y2="42.38"
                                            style={{fill: "none", stroke: "#232323", strokeWidth: "2px"}}/>
                                      <line id="Linie_55" data-name="Linie 55" className="cls-2" x1="16" y1="35.46"
                                            x2="40" y2="35.46"
                                            style={{fill: "none", stroke: "#232323", strokeWidth: "2px"}}/>
                                      <line id="Linie_54" data-name="Linie 54" className="cls-2" x1="24" y1="28.54"
                                            x2="48" y2="28.54"
                                            style={{fill: "none", stroke: "#232323", strokeWidth: "2px"}}/>
                                      <line id="Linie_53" data-name="Linie 53" className="cls-2" x1="21" y1="21.62"
                                            x2="45" y2="21.62"
                                            style={{fill: "none", stroke: "#232323", strokeWidth: "2px"}}/>
                                    </g>
                                  </g>
                                </g>
                              </g>
                            </svg>
                        }
                      </span>
                      </Link>
                    </div>
                  </div>
                  <div className={`uber__row`}>
                    <div className="detail__quote">
                      <i>“</i>
                      <p>
                        dpi bedeutet nicht nur "dots per inch", sondern widerspiegelt vielmehr meinen Philosophie "design, powertools und inspiration".
                      </p>
                      <i>„</i>
                    </div>
                  </div>
                  <div className="uber__stage">
                    {this.state.active === "play" &&
                    <LottieControlled/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(AboutPage);
