import React, {Component} from "react";

class Button extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {
    return (
        <input type={this.props.type ? this.props.type : "button"} className="button" value={this.props.value}/>
    );
  }
}

export default Button;
