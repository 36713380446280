import React from 'react'
import Lottie from 'lottie-react';

class LottieControlled extends React.Component {

  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render() {
    // const defaultOptions = {
    //   loop: false,
    //   autoplay: true,
    //   animationData: this.props.animationData,
    //   rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice'
    //   }
    // };

    return <div>
      <Lottie
          // height={400}
          // width={400}
          //     isStopped={this.state.isStopped}
          //     isPaused={this.state.isPaused}
              animationData={this.props.animationData}
              loop={false}
              autoplay={true}

      />
    </div>
  }
}

export default LottieControlled;
