import React, {Component} from "react";
import {withRouter} from "react-router";
import Breadcrumb from "../components/atoms/Breadcrumb";

class BalancePage extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {
    return (
        <div className="design balance">
          <div className="content">
            <Breadcrumb current={"balance"} isBackVisible={true}/>
            <div className="content__inner--life">
              <div className="detail__container">
                <div className="uber__stage">
                  <div className="detail__quote">
                    <i>“</i>
                    <p>
                      Der Ort, an dem das Durchatmen am besten funktioniert
                    </p>
                    <i>„</i>
                  </div>

                  <a target={"_blank"} rel="noreferrer" href={"https://goo.gl/maps/BjZLBAgBKFaXQRiE7"}>
                    <div className="balance__destination">
                      <div className="balance__icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
                          <g id="Ebene_2" data-name="Ebene 2">
                            <g id="Ebene_1-2" data-name="Ebene 1">
                              <g id="Gruppe_549" data-name="Gruppe 549">
                                <circle id="Ellipse_31" data-name="Ellipse 31" className="cls-1" cx="20" cy="4" r="4"/>
                                <circle id="Ellipse_37" data-name="Ellipse 37" className="cls-1" cx="20" cy="36" r="4"/>
                                <circle id="Ellipse_34" data-name="Ellipse 34" className="cls-1" cx="20" cy="20" r="4"/>
                                <circle id="Ellipse_35" data-name="Ellipse 35" className="cls-1" cx="36" cy="20" r="4"/>
                                <circle id="Ellipse_36" data-name="Ellipse 36" className="cls-1" cx="4" cy="20" r="4"/>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <p>WGRF+G7 Big Sur, California, Vereinigte Staaten</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(BalancePage);
