import React, {Component} from "react";
import Navbar from "../components/modules/Navbar";

class MainLayout extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {
    return (
        <div className={this.props.isDarkMode ? "dark-mode" : ""}>

          <Navbar isDarkMode={this.props.isDarkMode}/>

          {this.props.children}

        </div>
    );
  }
}

export default MainLayout;
