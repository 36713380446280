import React, {Component} from "react";
import CustomReel from "../components/elements/CustomReel";
import Breadcrumb from "../components/atoms/Breadcrumb";

class PortfolioPage extends Component {

  constructor(props, context) {
    super(props);

    this.state = {
      activeReel: 0,
      totalSlides: 2
    }
  }

  render() {

    return (
        <div className="home portfolio">
          <div className="content">

            <img src={`/assets/03_logo/vulcano_logo_${this.props.isDarkMode ? "black" : "white"}_nofont.png`}
                 alt="background"
                 className={`content__background`}/>

            <Breadcrumb current={"work"} isBackVisible={true}/>

            <CustomReel categories={["webdesign", "bachelorarbeit", "powerpoint"]}
                        projects={[
                          [
                            {
                              name: "contento",
                              assetPath: "/assets/02_pictures/00_projects/01_web/04_Contento/00_contento_teaser.jpg"
                            },
                            {
                              name: "puma",
                              assetPath: "/assets/02_pictures/00_projects/01_web/01_Puma/00_puma_teaser.jpg"
                            },
                            {
                              name: "schottel",
                              assetPath: "/assets/02_pictures/00_projects/01_web/02_Schottel/00_schottel_teaser.jpg"
                            },
                            {
                              name: "stihl",
                              assetPath: "/assets/02_pictures/00_projects/01_web/03_Stihl/00_stihl_teaser.jpg"
                            }
                          ],
                          [
                            {
                              name: "bachelorarbeit",
                              assetPath: "/assets/02_pictures/00_projects/03_ba/00_ba_teaser.jpg"
                            }
                          ],
                          [
                            {
                              name: "hager",
                              assetPath: "/assets/02_pictures/00_projects/02_ppt/02_Hager/00_hager_teaser.jpg"
                            },
                            {
                              name: "ferrari",
                              assetPath: "/assets/02_pictures/00_projects/02_ppt/01_Ferrari/00_ferrari_teaser.jpg"
                            }
                          ]
                        ]}/>

          </div>
        </div>
    );
  }
}

export default PortfolioPage;
