import './index.sass';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import DetailPage from "./pages/DetailPage";
import InfoSpacePage from "./pages/InfoSpacePage";
import PortfolioPage from "./pages/PortfolioPage";
import MainLayout from "./layouts/MainLayout";
import React, {useState} from "react";
import LifePage from "./pages/LifePage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import ImprintPage from "./pages/ImprintPage";
import InspirationPage from "./pages/InspirationPage";
import DesignPage from "./pages/DesignPage";
import PowertoolsPage from "./pages/PowertoolsPage";
import QuotePage from "./pages/QuotePage";
import BalancePage from "./pages/BalancePage";

function App() {

  const [isDarkMode, setDarkMode] = useState(true);

  function toggleDarkMode() {
    console.log("toggled")
    setDarkMode(!isDarkMode)
  }

  return (
      // <Router history={history}>
      <Router>
        <Switch>
          <Route exact path={"/"}>
            <InfoSpacePage isDarkMode={isDarkMode} toggleDarkMode={() => toggleDarkMode()}/>
          </Route>
          <Route exact path={"/work"}>
            <MainLayout isDarkMode={isDarkMode}>
              <PortfolioPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route exact path={"/life"}>
            <MainLayout isDarkMode={isDarkMode}>
              <LifePage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route exact path={"/about"}>
            <MainLayout isDarkMode={isDarkMode}>
              <AboutPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route exact path={"/about/inspiration"}>
            <MainLayout isDarkMode={isDarkMode}>
              <InspirationPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route exact path={"/about/design"}>
            <MainLayout isDarkMode={isDarkMode}>
              <DesignPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route exact path={"/about/powertools"}>
            <MainLayout isDarkMode={isDarkMode}>
              <PowertoolsPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route path={"/quote"}>
            <MainLayout isDarkMode={isDarkMode}>
              <QuotePage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route path={"/balance"}>
            <MainLayout isDarkMode={isDarkMode}>
              <BalancePage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route path={"/contact"}>
            <MainLayout isDarkMode={isDarkMode}>
              <ContactPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route path={"/imprint"}>
            <MainLayout isDarkMode={isDarkMode}>
              <ImprintPage isDarkMode={isDarkMode}/>
            </MainLayout>
          </Route>
          <Route path={"/detail/:project"}>
            <DetailPage isDarkMode={isDarkMode}/>
          </Route>
        </Switch>
      </Router>
  );
}

export default App;
