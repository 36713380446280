import React, {Component} from "react";
// import life from "../data/life.json";
import {withRouter} from "react-router";
import Breadcrumb from "../components/atoms/Breadcrumb";

class ContactPage extends Component {

  constructor(props, context) {
    super(props);
  }

  render() {

    // let data = this.state.lifeData

    return (
        <div className="life contact">
          <div className="content">
            <Breadcrumb current={"kontakt"} isBackVisible={true}/>
            <div className="contact__container">
              <div className="contact__row">
                <div className="contact__col">
                  <div className="detail__quote">
                    <i>“</i>
                    <p>
                      Kreative Ideen, Strategien und Tatendrang?<br/>
                      Ich werde Sie in Ihren individuellen Vorstellungen von der Idee über die Konzeption, Koordinierung und Projektplanung bis hin zur Finalisierung des Produktes professionell beraten und tatkräftig unterstützen. Ich freue mich auf eine produktive Zusammenarbeit.
                    </p>
                    <i>„</i>
                  </div>
                  <div className="contact__info">
                    <p>EMAIL_</p>
                    <p>julianfaller22@gmail.com</p>
                  </div>
                  <div className="contact__info">
                    <p>MOBILE_</p>
                    <p>017662732872</p>
                  </div>
                  <div className="contact__info">
                    <p>NAME_</p>
                    <p>Julian David Faller</p>
                  </div>
                </div>
                <div className="contact__col">
                  <div className="contact__image">
                    <img src="/assets/02_pictures/02_contact/kontakt.jpg" alt="JulianFoto"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(ContactPage);
