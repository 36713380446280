import React, {Component} from "react";
import {Link} from "react-router-dom";
import ReelCard from "./ReelCard";
import {tns} from 'tiny-slider/src/tiny-slider'

class CustomReel extends Component {

  constructor(props, context) {
    super(props);

    this.state = {
      activeReel: 0,
      totalSlides: this.props.categories.length
    }
  }

  setActiveReel(newReel) {
    this.setState({
      activeReel: newReel
    })
  }

  initSliders = () => {
    let sliderArray = [];
    [0, 2].map(i => {
      if(i !== 1)
        sliderArray.push(
            tns({
              container: `.reel__no-slider--${i}`,
              items: 2,
              slideBy: 2,
              controls: true,
              loop: false,
              rewind: false,
              gutter: 40,
              autoplay: false,
              nav: true,
              prevButton: `.reel__arrow--left--${i}`,
              nextButton: `.reel__arrow--right--${i}`,
            })
        )
      return true;
    })
    // reelSlider.events.on('transitionEnd', (info));
  }

  componentDidMount() {
    this.initSliders()
  }

  render() {
    // let totalSlidesTransform = this.state.activeReel === 0 ? ((this.state.activeReel * 100) / this.state.totalSlides) : ((this.state.activeReel * 100) / this.state.totalSlides) + 2
    return (
        <div className="reel__container">
          <div className={"reel__object"}>
            <div className="reel__header">
              <div className="reel__sub">
                {this.props.categories.map((cat, i) => {
                  return (
                      <>
                      <h4 className={this.state.activeReel === i ? "active" : ""}
                          onClick={() => this.setActiveReel(i)}>{cat}</h4>
                        <span></span>
                      </>
                  )
                })}
              </div>
            </div>
            <div className={`reel__wrapper`}>
              <div className="reel__track"
                   style={{
                     // width: `${this.state.totalSlides}00%`,
                     // transform: `translateX(-${totalSlidesTransform}%)`
                   }}>
                {this.props.projects.map((category, i) => {
                  return (
                      <div
                          className={`reel__inner ${this.state.activeReel === i ? "active" : ""}`}>

                        <div className="reel__container reel__container--round reel__detail">
                          <span className={`reel__arrow reel__arrow--left reel__arrow--left--${i}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g
                                id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect
                                id="Rechteck_26"
                                data-name="Rechteck 26"
                                className="cls-1"
                                x="-2.69" y="9.31"
                                width="28" height="4"
                                transform="translate(-4.69 11.31) rotate(-45)"/><rect
                                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28"
                                width="4"
                                height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
                          </span>

                          <div className={"reel__object"}>
                            <div className={`reel__no-slider reel__no-slider--${i} reel__actual-slider`}>
                              {category.map((project, j) => {
                                return (
                                    <Link to={`/detail/${project.name}`}>
                                      <ReelCard isAssetOnly={true}
                                                assetPath={project.assetPath}
                                      />
                                    </Link>
                                )
                              })}
                            </div>
                          </div>
                          <span className={`reel__arrow reel__arrow--right reel__arrow--right--${i}`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g
                                id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect
                                id="Rechteck_26"
                                data-name="Rechteck 26"
                                className="cls-1"
                                x="-2.69" y="9.31"
                                width="28" height="4"
                                transform="translate(-4.69 11.31) rotate(-45)"/><rect
                                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28"
                                width="4"
                                height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
                          </span>
                        </div>
                      </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default CustomReel;
