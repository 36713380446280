import React, {Component} from "react";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";
import {AppContext} from "../ContextProvider";

class Breadcrumb extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isBackVisible: this.props.isBackVisible ? true : false
    }
  }

  goBack = () => {
    this.props.history.goBack()
  }

  render() {
    return (
        <>
          <div className="breadcrumb">
            <ul>
              <li>
              <span>
                <Link to={{pathname: `/`}} onClick={() => this.context.setSpaceOpen(false)}>
                  Startseite
                </Link>
              </span>
              </li>
              <li>
              <span>
                <span>/</span>
              </span>
              </li>
              <li>
              <span>
                <Link to={{pathname: `/`}} onClick={() => this.context.setSpaceOpen(true)}>
                  Menu
                </Link>
              </span>
              </li>
              {this.props.previous &&
              <>
                <li>
                <span>
                  <span>/</span>
                </span>
                </li>
                <li>
                <span>
                  <Link to={{pathname: `/about`}}>
                    {this.props.previous}
                  </Link>
                </span>
                </li>
              </>
              }
              {this.props.current &&
              <>
                <li>
                <span>
                  <span>/</span>
                </span>
                </li>
                <li>
                <span className={"current"}>
                  {this.props.current}
                </span>
                </li>
              </>
              }
            </ul>
            {this.state.isBackVisible &&
            <div className="breadcrumb__back" onClick={() => this.props.history.goBack()}>
              <span className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><g
                    id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect id="Rechteck_26"
                                                                                                 data-name="Rechteck 26"
                                                                                                 className="cls-1"
                                                                                                 x="-2.69" y="9.31"
                                                                                                 width="28" height="4"
                                                                                                 transform="translate(-4.69 11.31) rotate(-45)"/><rect
                    id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28" width="4"
                    height="28"
                    transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
              </span>
              <span>zurück</span>
            </div>
            }
          </div>
        </>
    );
  }
}

Breadcrumb.contextType = AppContext

export default withRouter(Breadcrumb);
