import React, {Component} from "react";
import Button from "../atoms/Button";

class Card extends Component {

  constructor(props, context) {
    super(props);

    this.state = {}
  }

  toggleActive = () => {
  }

  render() {
    return (
        <div className="card">
          <div className={"card__inner"}>
            <ul className={"card__actions"}>
              <li>o</li>
              <li>x</li>
            </ul>

            <span className={"card__sub"}>just duo dolores</span>
            <span className={"card__title"}>Lorem impusm und so</span>
            <p className={"card__desc"}>Lorem impusm und so so Lorem impusm und so so so lo lsal l a Lorem impusm und so
              Lorem impusm und so Lorem impusm und so so Lorem impusm und so so so lo lsal l a Lorem impusm und so Lorem
              impusm und so</p>

            <Button value={"play now"}/>
          </div>
        </div>
    );
  }
}

export default Card;
