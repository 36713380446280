import React, {Component} from "react";
import {Link} from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import ParallaxMousemove from 'react-parallax-mousemove'
import {AppContext} from "../components/ContextProvider";

class InfoSpacePage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSpaceOpen: false
    }

  }

  render() {

    const style = {
      outter: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        width: '100%',
        height: '100%'
      },
      bgLayer: {
        position: 'absolute',
        width: '800px',
        height: '800px',
      },
      logoLayer: {
        position: 'relative',
        // transform: 'translate(-50%, -50%)'
      },
      menuLayerLeft: {
        position: 'relative',
        // top: '50%',
        // transform: 'translateY(-50%)',
      },
      menuLayerRight: {
        position: 'relative',
        // top: '50%',
        // transform: 'translateY(-50%)',
      },
    }

    return (
        <MainLayout isDarkMode={this.props.isDarkMode}>

          <div className="home">
            <div className={`space ${this.context.isSpaceOpen ? "space--open" : ""}`} id={"space__container"}>
              <div className="space__container">
                <div className="space__row" id={"space__inner"}>

                  <ParallaxMousemove containerStyle={style.outter} fullHeight={true}>
                    <ParallaxMousemove.Layer layerStyle={style.bgLayer} config={{
                      xFactor: -0.03,
                      yFactor: -0.025,
                      springSettings: {
                        stiffness: 20,
                        damping: 10
                      }
                    }}>
                      <img src={`/assets/03_logo/vulcano_logo_${this.props.isDarkMode ? "black" : "white"}_nofont.png`}
                           alt="background"
                           className={`space__background`}/>
                    </ParallaxMousemove.Layer>
                    <ParallaxMousemove.Layer layerStyle={style.menuLayerLeft} config={{
                      xFactor: 0.05,
                      yFactor: 0.05,
                      springSettings: {
                        stiffness: 50,
                        damping: 30
                      }
                    }}>
                      <div className="space__column space__column--first">
                        <ul>
                          <li className="space__item">
                            <Link to="/work">Work</Link>
                            <div className={`space__item-details`}>
                              <img src={`assets/ui/icons/icon--work${this.props.isDarkMode ? "" : "-yellow"}.svg`}
                                   alt="work"/>
                            </div>
                          </li>
                          <i></i>
                          <li className="space__item">
                            <Link to="/life">Life</Link>
                            <div className={`space__item-details`}>
                              <img src={`assets/ui/icons/icon--life${this.props.isDarkMode ? "" : "-yellow"}.svg`}
                                   alt="life"/>
                            </div>
                          </li>
                          <i></i>
                          <li className="space__item">
                            <Link to="/balance">Balance</Link>
                            <div className={`space__item-details`}>
                              <img src={`assets/ui/icons/icon--balance${this.props.isDarkMode ? "" : "-yellow"}.svg`}
                                   alt="balance"/>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </ParallaxMousemove.Layer>
                    <ParallaxMousemove.Layer layerStyle={style.logoLayer} config={{
                      xFactor: 0.1,
                      yFactor: 0.1,
                      springSettings: {
                        stiffness: 100,
                        damping: 60
                      }
                    }}>
                      <div className="space__column space__column--middle">
                        <div className={`space__intro ${this.props.isDarkMode ? "space__intro--dark" : ""}`}>
                          <Link className={"quote__open"} to={"/quote"}>
                            *
                          </Link>
                          <span className="space__intro-arrow" onClick={() => this.props.toggleDarkMode()}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g
                                id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect
                                id="Rechteck_26"
                                data-name="Rechteck 26"
                                className="cls-1"
                                x="-2.69" y="9.31"
                                width="28" height="4"
                                transform="translate(-4.69 11.31) rotate(-45)"/><rect
                                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28"
                                width="4"
                                height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g
                                id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect
                                id="Rechteck_26"
                                data-name="Rechteck 26"
                                className="cls-1"
                                x="-2.69" y="9.31"
                                width="28" height="4"
                                transform="translate(-4.69 11.31) rotate(-45)"/><rect
                                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28"
                                width="4"
                                height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
                          </span>
                          <div className="space__column-logo">
                            <img src={`/assets/03_logo/vulcano_logo_${this.props.isDarkMode ? "black" : "white"}.png`}
                                 alt="logo" onClick={() => this.context.toggleSpaceOpen()}/>
                          </div>
                          <span className="space__intro-arrow" onClick={() => this.props.toggleDarkMode()}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g
                                id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect
                                id="Rechteck_26"
                                data-name="Rechteck 26"
                                className="cls-1"
                                x="-2.69" y="9.31"
                                width="28" height="4"
                                transform="translate(-4.69 11.31) rotate(-45)"/><rect
                                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28"
                                width="4"
                                height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.63 39.6"><defs></defs><g
                                id="Ebene_2" data-name="Ebene 2"><g id="Ebene_1-2" data-name="Ebene 1"><rect
                                id="Rechteck_26"
                                data-name="Rechteck 26"
                                className="cls-1"
                                x="-2.69" y="9.31"
                                width="28" height="4"
                                transform="translate(-4.69 11.31) rotate(-45)"/><rect
                                id="Rechteck_26-2" data-name="Rechteck 26" className="cls-1" x="9.31" y="14.28"
                                width="4"
                                height="28" transform="translate(-16.69 16.28) rotate(-45)"/></g></g></svg>
                          </span>
                        </div>
                      </div>
                    </ParallaxMousemove.Layer>

                    <ParallaxMousemove.Layer layerStyle={style.menuLayerRight} config={{
                      xFactor: 0.05,
                      yFactor: 0.05,
                      springSettings: {
                        stiffness: 50,
                        damping: 30
                      }
                    }}>
                      <div className="space__column space__column--second">
                        <ul>
                          <li className="space__item">
                            <Link to="/contact">Kontakt</Link>
                            <div className={`space__item-details`}>
                              <img src={`assets/ui/icons/icon--kontakt${this.props.isDarkMode ? "" : "-yellow"}.svg`}
                                   alt="contact"/>
                            </div>
                          </li>
                          <i></i>
                          <li className="space__item">
                            <Link to="/imprint">Impressum</Link>
                            <div className={`space__item-details`}>
                              <img src={`assets/ui/icons/icon--impressum${this.props.isDarkMode ? "" : "-yellow"}.svg`}
                                   alt="imprint"/>
                            </div>
                          </li>
                          <i></i>
                          <li className="space__item">
                            <Link to="/about">Über</Link>
                            <div className={`space__item-details`}>
                              <img src={`assets/ui/icons/icon--ueber${this.props.isDarkMode ? "" : "-yellow"}.svg`}
                                   alt="about"/>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </ParallaxMousemove.Layer>

                  </ParallaxMousemove>
                </div>
              </div>

              {!this.context.isSpaceOpen &&
              <div className="space__lower">
                <span>Visual</span>
                <span>COMMUNICATION</span>
                <span>DESIGN</span>
              </div>
              }
            </div>
          </div>
        </MainLayout>
    );
  }
}

InfoSpacePage.contextType = AppContext;

export default InfoSpacePage;
