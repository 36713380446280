import React, {Component} from "react";
import LottieControlled from "../components/atoms/LottieControlled";
import Breadcrumb from "../components/atoms/Breadcrumb";
import designWhite from "../lottie/anim--powertools-black.json"
import designBlack from "../lottie/anim--powertools-white.json"
import {withRouter} from "react-router";

class PowertoolsPage extends Component {

  constructor(props, context) {
    super(props);

    let timer;
  }

  componentDidMount() {
    this.timer = setTimeout(() => {
      this.props.history.push("/about")
    }, 7000);
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    return (
        <div className="life__upper power">
          <div className="content">
            <Breadcrumb previous={"über"} current={"powertools"} isBackVisible={true}/>
            <div className="content__inner--life">
              <div className="detail__container">
                <div className="detail__header">
                  <div className="uber__stage">
                    <LottieControlled
                        animationData={this.props.isDarkMode ? designBlack : designWhite}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default withRouter(PowertoolsPage);
