import React, {Component} from "react";
import life from "../data/life.json";
import {withRouter} from "react-router";
import Breadcrumb from "../components/atoms/Breadcrumb";
import ScrollUp from "../components/atoms/ScrollUp";

class LifePage extends Component {

  constructor(props, context) {
    super(props);

    this.state = {
      lifeData: life,
    }
  }

  render() {

    let data = this.state.lifeData

    return (
        <div className="life">
          <div className="life__hero">

            <img src={`/assets/03_logo/vulcano_logo_${this.props.isDarkMode ? "black" : "white"}_nofont.png`}
                 alt="background"
                 className={`life__background`}/>

            <div className="content">
              <div className="life__upper">
                <Breadcrumb isBackVisible={true} current={"life"}/>
                <div className="detail__quote">
                  <i>“</i>
                  <p>
                    {data.quote}
                  </p>
                  <i>„</i>
                </div>

                {/*<div className="life__explanation">*/}
                {/*  <i>*</i>*/}
                {/*  <p>*/}
                {/*    Dieses Bild entstand als Teil eines Hochschulprojektes mit dem Thema: Identität.*/}
                {/*  </p>*/}
                {/*</div>*/}
              </div>

              <div className="content__inner--life">
                <div className="detail__container">

                  <div className="detail-bar__container">
                    <div className="detail__header">
                      {life.milestones.map(m => {
                        return (
                            <div className="detail__row">
                              <div className="detail__col life__col">
                                <h2>{m.year}</h2>
                                <h4>{m.headline} <i>{m.headlineColor}</i></h4>
                                <p>{m.description}</p>
                              </div>
                              <div className="detail__col life__col">
                                <h2>{m.year}</h2>
                                <h4>{m.headline} <i>{m.headlineColor}</i></h4>
                                <p>{m.description}</p>
                              </div>
                            </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ScrollUp/>
        </div>
    );
  }
}

export default withRouter(LifePage);
